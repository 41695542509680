import { SEO } from '~modules'
/* @TODO: fix alias, ~components not working */
import { components } from '../components'
import { JsonLD } from '~elements'
import { Page as DefaultPage } from '~modules'
import { fetchPageBySlug, fetchConstantsMaps } from '../lib/hygraph'
import { REVALIDATION_INTERVAL, SLUG_HOMEPAGE } from '~constants'
import { getConstantsMapFromCms } from 'utils'
import { resolvePageBlocks } from 'utils/query/queryResolver'

interface StoryInterface {
  name: string
  content: object
}

interface PageProps {
  story: StoryInterface
  preview: boolean
}

export default function Home({ story, preview }: PageProps) {
  const { content } = story
  const { body } = content
  const { seo, title } = body

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'Sphere',
    url: 'https://www.thespherevegas.com/'
  }

  return (
    <>
      <JsonLD data={structuredData} />
      <SEO defaultTitle={title} {...seo} />
      <DefaultPage blok={content} components={components} preview={preview} />
    </>
  )
}

export async function getStaticProps({ preview = false }) {
  const data = await fetchPageBySlug(preview, { slug: SLUG_HOMEPAGE })

  const pages = await data.pages.map(async page => {
    const clonedPage = { ...page }
    clonedPage.blocks = await resolvePageBlocks(clonedPage)
    return clonedPage
  })

  const [allPages] = await Promise.all(pages)

  const constantsMapsArray = await fetchConstantsMaps(preview)
  const constantsMap = getConstantsMapFromCms(constantsMapsArray.constantMaps)

  return {
    props: {
      story: data ? { content: { body: { ...allPages, constantsMap } } } : false,
      preview
    },
    revalidate: REVALIDATION_INTERVAL
  }
}
